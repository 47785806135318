@use 'common'

.wrapper
	@media (min-width: common.$break62)
		padding-top: 5rem
		padding-bottom: 5rem

.homePageStyles
	margin-bottom: -3rem
	margin-top: -1rem

	@media (min-width: common.$break62)
		margin-bottom: -6rem
		margin-top: -3rem

.title
	+common.title
	font-weight: 400
	margin: 0
	margin-bottom: 1.875rem
	text-align: center
	margin-bottom: 3.75rem
	font-size: 1.5em !important
	padding-right: 2rem
	padding-left: 2rem

	@media (min-width: common.$break32)
		font-size: 2.5em !important

	@media (min-width: common.$break62)
		font-size: 3em !important

.logos
	//

.logo
	//
